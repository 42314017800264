// Import core indentity code
import netlifyIdentity from "netlify-identity-widget";

function redirectToDashboard() {
  document.location.href = "/admin/";
}

function handleInit(user) {
  // If there isn’t a stored user
  if (!user) {
    // Register login handler, redirecting to the dashboard
    netlifyIdentity.on("login", redirectToDashboard);
  }
}

// Register init handler
netlifyIdentity.on("init", handleInit);

// Init
netlifyIdentity.init();
